import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";
export const applePayValidation = createAsyncThunk(
  "applePayValidation",
  async (data) => {
    try {
      const response = await AxiosInstance.post(`/setting/apple-pay-validation`, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const applePayValidationSlice = createSlice({
  name: "applePayValidation",
  initialState: {
    isLoading: false,
    applePayValidationData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [applePayValidation.pending]: (state) => {
      state.isLoading = true;
    },
    [applePayValidation.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.applePayValidationData = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [applePayValidation.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default applePayValidationSlice.reducer;
